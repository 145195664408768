<template>
  <Header />
  <main class="page-wrapper">

    <Hero />
    <!-- Brands (carousel on screens < 1100px) -->
    <section class="container border-bottom-lg pt-sm-2">
      <div class="swiper mx-n2" data-swiper-options='{
    "slidesPerView": 2,
    "pagination": {
      "el": ".swiper-pagination",
      "clickable": true
    },
    "breakpoints": {
      "500": {
        "slidesPerView": 3,
        "spaceBetween": 8
      },
      "650": {
        "slidesPerView": 4,
        "spaceBetween": 8
      },
      "850": {
        "slidesPerView": 5,
        "spaceBetween": 8
      },
      "992": {
        "slidesPerView": 6,
        "spaceBetween": 8
      }
    }
  }'>
        <div class="swiper-wrapper">

          <!-- Item -->
          <div class="swiper-slide py-2">
            <a href="#" class="px-2 mx-2">
              <img src="assets/img/throwbit_logo.png" class="d-block mx-auto my-2" width="200" alt="Brand">
            </a>
          </div>

          <!-- Item -->
          <div class="swiper-slide py-2">
            <a href="#" class="px-2 mx-2">
              <img src="assets/img/brands/02.svg" class="d-block mx-auto my-2" width="155" alt="Brand">
            </a>
          </div>

          <!-- Item -->
          <div class="swiper-slide py-2">
            <a href="#" class="px-2 mx-2">
              <img src="assets/img/brands/03.svg" class="d-block mx-auto my-2" width="155" alt="Brand">
            </a>
          </div>

          <!-- Item -->
          <div class="swiper-slide py-2">
            <a href="#" class="px-2 mx-2">
              <img src="assets/img/brands/04.svg" class="d-block mx-auto my-2" width="155" alt="Brand">
            </a>
          </div>

          <!-- Item -->
          <div class="swiper-slide py-2">
            <a href="#" class="px-2 mx-2">
              <img src="assets/img/brands/05.svg" class="d-block mx-auto my-2" width="155" alt="Brand">
            </a>
          </div>

          <!-- Item -->
          <div class="swiper-slide py-2">
            <a href="#" class="px-2 mx-2">
              <img src="assets/img/brands/06.svg" class="d-block mx-auto my-2" width="155" alt="Brand">
            </a>
          </div>
        </div>

        <!-- Pagination (bullets) -->
        <div class="swiper-pagination position-relative pt-3"></div>
      </div>
    </section>


    <section class="container py-5 my-md-2 my-lg-4 my-xl-5">
      <!-- Text -->
      <h2 class="h1 text-center mx-auto mt-n2 mt-sm-0 pt-md-2" style="max-width: 530px;">Explorez les possibilités de
        trading avec Throwbit</h2>
      <ul class="list-unstyled d-flex flex-wrap justify-content-center mb-5">
        <li class="d-flex fs-xl mx-3 mt-2 mt-sm-3">
          <i class="bx bx-check lead text-primary mt-1 me-2"></i>
          <span>Dépensez avec les cryptomonnaies et obtenez jusqu'à <strong class="text-primary ms-1">12% de
              retour</strong></span>
        </li>
        <li class="d-flex fs-xl mx-3 mt-2 mt-sm-3">
          <i class="bx bx-check lead text-primary mt-1 me-2"></i>
          <span>Invitez des amis et découvrez <strong class="text-primary ms-1">de nouvelles
              opportunités</strong></span>
        </li>
        <li class="d-flex fs-xl mx-3 mt-2 mt-sm-3">
          <i class="bx bx-check lead text-primary mt-1 me-2"></i>
          <span>Tradez et gagnez avec <strong class="text-primary mx-1">200+ cryptomonnaies</strong> et bénéficiez d'une
            <strong class="text-primary ms-1">remise permanente de 5%</strong></span>
        </li>
      </ul>

      <!-- Parallax gfx -->
      <div class="position-relative px-4 px-sm-5 mb-3 mb-md-4">
        <img src="assets/img/logo_TB_COULEUR-03.png" width="1079" class="d-block mx-auto"
          style="border-radius: 1.5rem; box-shadow: 0 1.875rem 7.5rem -.625rem rgba(124,125,152, .2);" alt="Dashboard">
        <div class="position-absolute w-100" style="left: 0; bottom: 13%; padding-right: 74.33%;">
          <img src="assets/img/landing/saas-3/feature-1/popup01.png" width="308" class="rellax d-block"
            style=" border-radius: .75rem; box-shadow: 0 .75rem 3.75rem -.625rem rgba(124,125,152, .2);" alt="Popup"
            data-rellax-percentage="0.5" data-rellax-speed="1" data-disable-parallax-down="lg">
        </div>
        <div class="position-absolute w-100" style="right: 0; top: 12%; padding-left: 74.16%;">
          <img src="assets/img/landing/saas-3/feature-1/popup02.png" width="310" class="rellax d-block"
            style="border-radius: .75rem; box-shadow: -.46875rem 2.25rem 6.5rem 0 rgba(126,123,160, .2);" alt="Popup"
            data-rellax-percentage="0.5" data-rellax-speed="1" data-disable-parallax-down="lg">
        </div>
        <div class="position-absolute w-100" style="right: 0; bottom: 9.4%; padding-left: 64.83%; padding-right: 5.5%;">
          <img src="assets/img/landing/saas-3/feature-1/popup03.png" width="322" class="rellax d-block ms-auto"
            style="border-radius: .75rem; box-shadow: -.46875rem 2.25rem 6.5rem 0 rgba(120,118,148, .14);" alt="Popup"
            data-rellax-percentage="0.5" data-rellax-speed=".5" data-disable-parallax-down="lg">
        </div>
      </div>
    </section>



    <section class="container pb-5 mb-md-2 mb-lg-4 mb-xl-5">
      <div class="row align-items-center pt-2 pb-3">
        <!-- Texte -->
        <div class="col-md-6 col-xl-5 text-center text-md-start mb-5 mb-md-0">
          <h2 class="h1 pb-2 pb-lg-3">Explorez les opportunités avec l'application Throwbit</h2>
          <p class="pb-2 mb-4 mb-lg-5">Découvrez une nouvelle ère de possibilités avec Throwbit. Notre application
            révolutionnaire vous permet de naviguer sans effort dans l'univers complexe des crypto-monnaies et du
            trading, offrant des outils avancés pour optimiser vos investissements.</p>
          <hr>
          <div class="d-flex justify-content-center justify-content-md-between pt-4 pt-lg-5">
            <div class="mx-3 mx-md-0">
              <div class="display-3 text-dark mb-1">23%</div>
              <span>Retour sur investissement potentiel</span>
            </div>
            <div class="mx-3 mx-md-0">
              <div class="display-3 text-dark mb-1">132b</div>
              <span>Options de trading disponibles</span>
            </div>
          </div>
        </div>

        <!-- Graphismes de parallaxe -->
        <div class="col-md-6 offset-xl-1">
          <div class="parallax ratio ratio-1x1 mx-auto" style="max-width: 550px;">
            <div class="parallax-layer position-absolute zindex-2" data-depth="-0.15">
              <img src="assets/img/landing/saas-3/feature-2/avatars.png" alt="Avatar">
            </div>
            <div
              class="parallax-layer d-flex justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100"
              data-depth="0.1">
              <div class="rounded-circle bg-primary" style="width: 70%; height: 70%; opacity: .06;"></div>
            </div>
            <div
              class="parallax-layer d-flex justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100"
              data-depth="0.2">
              <div class="rounded-circle bg-primary" style="width: 55%; height: 55%; opacity: .06;"></div>
            </div>
            <div
              class="parallax-layer d-flex justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100 zindex-3"
              data-depth="0.4">
              <div class="d-flex justify-content-center align-items-center rounded-circle bg-primary"
                style="width: 93px; height: 93px; box-shadow: 0 .1875rem 1.875rem -.125rem rgba(99,102,241, .95); ">
                <span class="h5 text-light mb-0">400K+</span>
              </div>
            </div>
            <div
              class="parallax-layer d-flex justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100"
              data-depth="0.3">
              <div class="rounded-circle bg-primary" style="width: 40%; height: 40%; opacity: .06;"></div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="container pt-2 pt-sm-4 pb-5 mb-md-2 mb-lg-4 mb-xl-5">
      <div class="row align-items-center mb-2 pb-sm-3 pb-md-4">

        <!-- Text -->
        <div class="col-md-5 col-lg-6 col-xl-5 offset-xl-1 order-md-2 text-center text-md-start mb-5 mb-md-0">
          <h2 class="h1 pb-2 pb-lg-3">Transformez votre vie avec Throwcoin</h2>
          <p class="pb-2 mb-4 mb-xl-5">Throwcoin vous offre une nouvelle façon de gérer vos finances numériques.
            Simplifiez vos transactions, maximisez vos investissements et explorez de nouvelles opportunités avec notre
            plateforme sécurisée et conviviale.</p>
          <hr>
          <div class="d-flex flex-wrap flex-sm-nowrap justify-content-center justify-content-md-between pt-3">
            <img src="assets/img/landing/saas-3/feature-3/binance.svg" width="48" class="d-block m-2 mx-md-0"
              alt="Bitcoin">
            <img src="assets/img/landing/saas-3/feature-3/ens.svg" width="48" class="d-block m-2 mx-md-0" alt="Ens">
            <img src="assets/img/landing/saas-3/feature-3/tether.svg" width="48" class="d-block m-2 mx-md-0"
              alt="Tether">
            <img src="assets/img/landing/saas-3/feature-3/alqo.svg" width="48" class="d-block m-2 mx-md-0" alt="Alqo">
            <img src="assets/img/landing/saas-3/feature-3/blockfi.svg" width="48" class="d-block m-2 mx-md-0"
              alt="BlockFi">
            <img src="assets/img/landing/saas-3/feature-3/bitcoin.svg" width="48" class="d-block m-2 mx-md-0"
              alt="Bitcoin">
          </div>
        </div>

        <!-- Parallax graphics -->
        <div class="col-md-7 col-lg-6 order-md-1">
          <div class="position-relative pt-5" style="max-width: 636px;">
            <img src="assets/img/landing/saas-3/feature-3/popup.png" class="rellax position-absolute top-0 mt-n5"
              alt="Card" data-rellax-percentage="0.5" data-rellax-speed="1" data-disable-parallax-down="lg">
            <img src="assets/img/landing/saas-3/feature-3/dashboard.jpg"
              class="d-block rounded-3 mt-sm-4 mt-md-0 mt-lg-4" alt="Dashboard"
              style="box-shadow: 0 1.875rem 7.5rem -.625rem rgba(124,125,152, .2);">
          </div>
        </div>
      </div>
    </section>


    <Step />
    <Testimonial />
    <!-- CTA -->
    <section class="container">
      <div
        class="bg-dark bg-size-cover bg-position-center bg-repeat-0 position-relative overflow-hidden rounded-3 py-lg-3 py-xl-5 px-4 px-lg-5 px-xl-0"
        style="background-image: url(assets/img/landing/saas-3/cta-bg.jpg);" data-bs-theme="dark">
        <div class="row position-relative zindex-2 py-5 my-1 my-md-3">
          <div class="col-md-6">
            <div class="mx-auto" style="max-width: 440px;">
              <h2 class="h1 pb-1 pb-md-3 pb-lg-4">Simplifiez Votre Parcours d'Investissement!</h2>
              <ul class="list-unstyled fs-lg d-md-none pb-3">
                <li class="d-flex text-body mb-2">
                  <i class="bx bx-check lead text-primary me-2" style="margin-top: .125rem;"></i>
                  Découvrez des solutions d'investissement sans tracas
                </li>
                <li class="d-flex text-body mb-2">
                  <i class="bx bx-check lead text-primary me-2" style="margin-top: .125rem;"></i>
                  Profitez d'une gestion financière sécurisée et efficace
                </li>
                <li class="d-flex text-body">
                  <i class="bx bx-check lead text-primary me-2" style="margin-top: .125rem;"></i>
                  Prenez le contrôle de votre avenir financier en toute confiance
                </li>
              </ul>
              <a href="/signup" class="btn btn-lg btn-primary shadow-primary w-100 w-sm-auto">Commencez dès
                maintenant</a>
            </div>
          </div>
          <div class="col-md-6 pt-lg-3 pt-xl-4">
            <ul class="list-unstyled fs-lg d-none d-md-block">
              <li class="d-flex text-body mb-2">
                <i class="bx bx-check lead text-primary me-2" style="margin-top: .125rem;"></i>
                Découvrez des solutions d'investissement sans tracas
              </li>
              <li class="d-flex text-body mb-2">
                <i class="bx bx-check lead text-primary me-2" style="margin-top: .125rem;"></i>
                Profitez d'une gestion financière sécurisée et efficace
              </li>
              <li class="d-flex text-body">
                <i class="bx bx-check lead text-primary me-2" style="margin-top: .125rem;"></i>
                Prenez le contrôle de votre avenir financier en toute confiance
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

  </main>

  <Footer />

  <a href="#top" class="btn-scroll-top" data-scroll>
    <span class="btn-scroll-top-tooltip text-muted fs-sm me-2">Top</span>
    <i class="btn-scroll-top-icon bx bx-chevron-up"></i>
  </a>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Step from '@/components/Step.vue';
import Hero from '@/components/Hero.vue';
import Testimonial from '@/components/Testimonial.vue';
import axios from '@/axios/axios.js';

export default {
  components: {
    Header,
    Footer,
    Step,
    Hero,
    Testimonial
  },
  data() {
    return {
    };
  },
  methods: {

  },
};
</script>
