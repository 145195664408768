<template>
    <section class="position-relative h-100 pt-5 pb-4">

        <!-- Formulaire d'inscription -->
        <div class="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100 pt-5">
            <div class="w-100 align-self-end pt-1 pt-md-4 pb-4" style="max-width: 526px;">
                <h1 class="text-center text-xl-center">Créer un compte</h1>
                <p class="text-center text-xl-start pb-3 mb-3">Vous avez déjà un compte? <a href="/login">Connectez-vous
                        ici.</a></p>
                <form class="needs-validation" novalidate>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="position-relative mb-4">
                                <label for="name" class="form-label fs-base">Nom</label>
                                <input type="text" id="name" class="form-control form-control-lg" required>
                                <div class="invalid-feedback position-absolute start-0 top-100">Veuillez entrer votre
                                    nom!</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="position-relative mb-4">
                                <label for="name" class="form-label fs-base">Prénom</label>
                                <input type="text" id="name" class="form-control form-control-lg" required>
                                <div class="invalid-feedback position-absolute start-0 top-100">Veuillez entrer votre
                                    nom!</div>
                            </div>
                        </div>

                        <div class="col-12 mb-4">
                            <label for="email" class="form-label fs-base">Email</label>
                            <div class="password-toggle">
                                <input type="email" id="email" class="form-control form-control-lg" required>
                                <div class="invalid-feedback position-absolute start-0 top-100">Veuillez entrer un email
                                    valide!</div>
                            </div>
                        </div>

                        <div class="col-12 mb-4">
                            <label for="password" class="form-label fs-base">Mot de passe</label>
                            <div class="password-toggle">
                                <input type="password" id="password" class="form-control form-control-lg" required>
                                <label class="password-toggle-btn" aria-label="Afficher/masquer le mot de passe">
                                    <input class="password-toggle-check" type="checkbox">
                                    <span class="password-toggle-indicator"></span>
                                </label>
                                <div class="invalid-feedback position-absolute start-0 top-100">Veuillez entrer un mot
                                    de passe!</div>
                            </div>
                        </div>
                        <div class="col-12 mb-4">
                            <label for="password-confirm" class="form-label fs-base">Confirmer le mot de passe</label>
                            <div class="password-toggle">
                                <input type="password" id="password-confirm" class="form-control form-control-lg"
                                    required>
                                <label class="password-toggle-btn" aria-label="Afficher/masquer le mot de passe">
                                    <input class="password-toggle-check" type="checkbox">
                                    <span class="password-toggle-indicator"></span>
                                </label>
                                <div class="invalid-feedback position-absolute start-0 top-100">Veuillez entrer un mot
                                    de passe!</div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-4">
                        <div class="form-check">
                            <input type="checkbox" id="terms" class="form-check-input">
                            <label for="terms" class="form-check-label fs-base">J'accepte les <a href="#">conditions
                                    générales</a></label>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary shadow-primary btn-lg w-100">S'inscrire</button>
                </form>
            </div>
        </div>

        <!-- Arrière-plan -->
        <div class="position-absolute top-0 end-0 w-50 h-100 bg-position-center bg-repeat-0 bg-size-cover d-none d-xl-block"
            style="background-image: url(assets/img/account/signin-bg.jpg);"></div>
    </section>
</template>

<script>
</script>