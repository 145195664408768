<template>
    <section class="position-relative h-100 pt-5 pb-4">
  
      <!-- Formulaire de connexion -->
      <div class="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100 pt-5">
        <div class="w-100 align-self-end pt-1 pt-md-4 pb-4" style="max-width: 526px;">
          <h1 class="text-center text-xl-center">Connectez-vous</h1>
          <p class="text-center text-xl-start pb-3 mb-3">Vous n'avez pas encore de compte? <a href="/signup">Inscrivez-vous ici.</a></p>
          <form class="needs-validation mb-2" novalidate>
            <div class="position-relative mb-4">
              <label for="email" class="form-label fs-base">Email</label>
              <input type="email" id="email" class="form-control form-control-lg" required>
              <div class="invalid-feedback position-absolute start-0 top-100">Veuillez entrer une adresse email valide!</div>
            </div>
            <div class="mb-4">
              <label for="password" class="form-label fs-base">Mot de passe</label>
              <div class="password-toggle">
                <input type="password" id="password" class="form-control form-control-lg" required>
                <label class="password-toggle-btn" aria-label="Afficher/masquer le mot de passe">
                  <input class="password-toggle-check" type="checkbox">
                  <span class="password-toggle-indicator"></span>
                </label>
                <div class="invalid-feedback position-absolute start-0 top-100">Veuillez entrer votre mot de passe!</div>
              </div>
            </div>
            <div class="mb-4">
              <div class="form-check">
                <input type="checkbox" id="remember" class="form-check-input">
                <label for="remember" class="form-check-label fs-base">Se souvenir de moi</label>
              </div>
            </div>
            <button type="submit" class="btn btn-primary shadow-primary btn-lg w-100">Se connecter</button>
          </form>
          <a href="/resset-password" class="btn btn-link btn-lg w-100">Mot de passe oublié?</a>
          <hr class="my-4">
          <h6 class="text-center mb-4"></h6>
          <div class="row row-cols-1 row-cols-sm-2">
          </div>
        </div>
      </div>
      <div class="position-absolute top-0 end-0 w-50 h-100 bg-position-center bg-repeat-0 bg-size-cover d-none d-xl-block" style="background-image: url(assets/img/account/signin-bg.jpg);"></div>
    </section>
  </template>
  
  <script>
  </script>
  