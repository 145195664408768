import axios from "axios";
import store from "../store";
import router from "../router";

const axiosClient = axios.create({
   baseURL: `http://localhost:8000/api`
  // baseURL: `https://throwbit.com/api/public/api`
});

axiosClient.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${store.state.user.token}`
  return config;
})

export default axiosClient;
