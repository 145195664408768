<template>
    <section class="position-relative h-100 pt-5 pb-4">
      <!-- Formulaire de réinitialisation de mot de passe -->
      <div class="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100 pt-5">
        <div class="w-100 align-self-end pt-1 pt-md-4 pb-4" style="max-width: 526px;">
          <h1 class="text-center text-xl-start">Réinitialiser le mot de passe</h1>
          <form class="needs-validation mb-2" novalidate @submit.prevent="resetPassword">
            <div class="position-relative mb-4">
              <label for="email" class="form-label fs-base">Email</label>
              <input type="email" id="email" class="form-control form-control-lg" required>
              <div class="invalid-feedback position-absolute start-0 top-100">Veuillez entrer une adresse email valide!</div>
            </div>
            <button type="submit" class="btn btn-primary shadow-primary btn-lg w-100">Réinitialiser</button>
          </form>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  </script>
  