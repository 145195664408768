<template>

    <!-- Comment ça marche (Étapes + Vidéo) -->
    <section class="container">
      <div class="text-center pb-4 pb-md-0 mb-2 mb-md-5 mx-auto" style="max-width: 530px;">
        <h2 class="h1">Comment Ça Marche ?</h2>
        <p class="mb-0">Découvrez comment utiliser Thowbit pour échanger des cryptomonnaies de manière simple et sécurisée.</p>
      </div>
  
      <!-- Étapes -->
      <div class="steps steps-sm steps-horizontal-md steps-center pb-5 mb-md-2 mb-lg-3">
        <div class="step">
          <div class="step-number">
            <div class="step-number-inner">1</div>
          </div>
          <div class="step-body">
            <h3 class="h4 mb-3">Inscription</h3>
            <p class="mb-0">Créez votre compte Thowbit en quelques minutes.</p>
          </div>
        </div>
        <div class="step">
          <div class="step-number">
            <div class="step-number-inner">2</div>
          </div>
          <div class="step-body">
            <h3 class="h4 mb-3">Vérification d'identité</h3>
            <p class="mb-0">Assurez-vous que votre identité est vérifiée pour des transactions sécurisées.</p>
          </div>
        </div>
        <div class="step">
          <div class="step-number">
            <div class="step-number-inner">3</div>
          </div>
          <div class="step-body">
            <h3 class="h4 mb-3">Commencez à trader</h3>
            <p class="mb-0">Échangez vos cryptomonnaies préférées avec d'autres utilisateurs de manière instantanée.</p>
          </div>
        </div>
        <div class="step">
          <div class="step-number">
            <div class="step-number-inner">4</div>
          </div>
          <div class="step-body">
            <h3 class="h4 mb-3">Gagnez avec Thowbit</h3>
            <p class="mb-0">Explorez des opportunités de revenus en utilisant les fonctionnalités avancées de Thowbit.</p>
          </div>
        </div>
      </div>
  
      <!-- Vidéo -->
      <div class="bg-secondary position-relative rounded-3 overflow-hidden px-4 px-sm-5">
        <div
          class="position-absolute top-50 start-50 w-75 h-75 translate-middle d-flex align-items-center justify-content-center zindex-5">
          <a href="https://www.youtube.com"
            class="btn btn-video btn-icon btn-xl bg-white stretched-link" data-bs-toggle="video"
            aria-label="Lire la vidéo">
            <i class="bx bx-play"></i>
          </a>
        </div>
        <div class="pt-4 mt-sm-3 px-3 px-sm-5 mx-md-5">
          <img src="assets/img/logo_TB_COULEUR-03.png" width="786" class="rellax d-block mx-auto mt-lg-4"
            alt="Card" data-rellax-percentage="0.5" data-rellax-speed="1.1" data-disable-parallax-down="lg">
        </div>
      </div>
    </section>
  
  </template>
  
  <script>
    // Ajoutez ici vos scripts si nécessaire
  </script>
  